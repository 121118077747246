// third-party
import { FormattedMessage } from 'react-intl';

import { ArchiveBox, Send, MusicPlaylist, MusicPlay , GalleryFavorite} from 'iconsax-react';

// icons
const icons = {
  artistsMenu: MusicPlay,
  albums: ArchiveBox,
  suggest: Send,
  songs: MusicPlaylist,
  favorite : GalleryFavorite

};

const artistsMenu = {
  id: 'group-artistsMenu',
  title: <FormattedMessage id="artistsMenu" />,
  icon: icons.artistsMenu,
  type: 'group',
  children: [
    {
      id: 'albums',
      title: <FormattedMessage id="albums" />,
      type: 'item',
      url: '/artist/albums',
      icon: icons.albums
    },
    {
      id: 'songs',
      title: <FormattedMessage id="songs" />,
      type: 'item',
      url: '/artist/songs',
      icon: icons.songs
    },
    {
      id: 'favorite',
      title: "Favorite",
      type: 'item',
      url: '/artist/favorite',
      icon: icons.favorite
    },
    {
      id: 'suggest',
      title: <FormattedMessage id="suggest" />,
      type: 'item',
      url: '/artist/suggest',
      icon: icons.suggest
    }

  ]
};

export default artistsMenu;
