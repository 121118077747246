// third-party
import { FormattedMessage } from 'react-intl';

import { MusicDashboard, UserSquare, MusicPlay, ArchiveBox, MusicPlaylist , FavoriteChart } from 'iconsax-react';

// icons
const icons = {
  dashboard: MusicDashboard,
  agentsMenu: MusicPlay,
  albums: ArchiveBox,
  artistIcon: UserSquare,
  songs: MusicPlaylist,
  favorite : FavoriteChart
};

const agentsMenu = {
  id: 'group-agentsMenu',
  title: <FormattedMessage id="agentsMenu" />,
  icon: icons.agentsMenu,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/agent/dashboard',
      icon: icons.dashboard
    },
    {
      id: 'artists',
      title: <FormattedMessage id="artists" />,
      type: 'item',
      url: '/agent/artists',
      icon: icons.agentsMenu
    },
    {
      id: 'albums',
      title: <FormattedMessage id="albums" />,
      type: 'item',
      url: '/agent/albums',
      icon: icons.albums
    },
    {
      id: 'songs',
      title: <FormattedMessage id="songs" />,
      type: 'item',
      url: '/agent/songs',
      icon: icons.songs
    },
    {
      id: 'favorite',
      title: "Favorite",
      type: 'item',
      url: '/agent/favorite',
      icon: icons.favorite
    },
    
    {
      id: 'profile',
      title: <FormattedMessage id="profile" />,
      type: 'item',
      url: '/agent/profile/basic',
      icon: icons.artistIcon
    }
  ]
};

export default agentsMenu;
