// third-party
import { FormattedMessage } from 'react-intl';

import { MusicPlaylist, MusicPlay, DirectboxReceive, MusicDashboard, UserSquare, TagUser } from 'iconsax-react';

// icons
const icons = {
  dashboard: MusicDashboard,
  artistsMenu: MusicPlay,
  songs: MusicPlaylist,
  userIcon: UserSquare,
  proposals: DirectboxReceive,
  artists: TagUser
};

const stationsMenu = {
  id: 'group-stationsMenu',
  title: <FormattedMessage id="stationsMenu" />,
  icon: icons.stationsMenu,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/radio-station/dashboard',
      icon: icons.dashboard
    },
    {
      id: 'artists',
      title: <FormattedMessage id="artists" />,
      type: 'item',
      url: '/radio-station/artists',
      icon: icons.artists
    },
    {
      id: 'radioSongs',
      title: <FormattedMessage id="songs" />,
      type: 'item',
      url: '/radio-station/songs',
      icon: icons.songs
    },
    {
      id: 'proposals',
      title: <FormattedMessage id="proposals" />,
      type: 'item',
      url: '/radio-station/proposals',
      icon: icons.proposals
    },
    {
      id: 'listen',
      title: <FormattedMessage id="listen" />,
      type: 'item',
      url: '/radio-station/listen',
      icon: icons.proposals
    },
    {
      id: 'selected',
      title: <FormattedMessage id="selected" />,
      type: 'item',
      url: '/radio-station/selected',
      icon: icons.proposals
    },
    {
      id: 'profile',
      title: <FormattedMessage id="profile" />,
      type: 'item',
      url: '/radio-station/profile/basic',
      icon: icons.userIcon
    }
  ]
};

export default stationsMenu;
