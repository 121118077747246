// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.apexcharts-legend-series .apexcharts-legend-marker {
  left: -4px !important;
  right: -4px !important;
}

.apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series,
.apexcharts-legend.apx-legend-position-top .apexcharts-legend-series {
  gap: 8px;
}
.apexcharts-legend-series {
  display: flex;
  gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/third-party/apex-chart.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;;EAEE,QAAQ;AACV;AACA;EACE,aAAa;EACb,QAAQ;AACV","sourcesContent":[".apexcharts-legend-series .apexcharts-legend-marker {\n  left: -4px !important;\n  right: -4px !important;\n}\n\n.apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series,\n.apexcharts-legend.apx-legend-position-top .apexcharts-legend-series {\n  gap: 8px;\n}\n.apexcharts-legend-series {\n  display: flex;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
