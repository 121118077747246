import { useContext } from 'react';
import { UserContext } from 'contexts/UserContext';
import adminsMenu from './admins-menu';
import artistsMenu from './artists-menu';
import settingsMenu from './settings-menu';
import stationsMenu from './stations-menu';
import agentsMenu from './agents-menu';

// ==============================|| MENU ITEMS ||============================== //

const MenuItems = () => {
  const { user } = useContext(UserContext);
  let items = [settingsMenu];

  if (user) {
    switch (user.role) {
      case 'Admin':
        items = [adminsMenu, settingsMenu];
        break;
      case 'Artist':
        items = [artistsMenu, settingsMenu];
        break;
      case 'Agent':
        items = [agentsMenu, settingsMenu];
        break;
      case 'Station':
        items = [stationsMenu, settingsMenu];
        break;
      default:
        items = [adminsMenu, artistsMenu, settingsMenu, stationsMenu];
    }
  } else {
    items = [adminsMenu, artistsMenu, settingsMenu, stationsMenu];
  }

  // Return an array with a unique key for each item
  return {
    items: items.map((item, index) => ({
      ...item,
      key: `menu-item-${index}` // Ensure each item has a unique key
    }))
  };
};

export default MenuItems;
